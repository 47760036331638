import { ClassType } from '@frontend/common';
import { DefaultModal, ModalFooter, ModalType, Spinner } from '@frontend/elements';
import { FaPlus } from 'react-icons/fa';
import { GrClose } from 'react-icons/gr';

import CreateRoleForm from './forms/create-role-form/create-role-form.component';
import RoleList from './role-list/role-list.component';
import useRolesModal from './roles-modal.controller';

const ID = 'roles-modal';

const RolesModal = () => {
    const viewProps = useRolesModal();

    return (
        <div id={ID}>
            <DefaultModal
                type={ModalType.PANE}
                handleClose={viewProps.handleClose}
                show>
                <div className='modal-header'>
                    <h4>Roles</h4>
                    <button
                        className='btn btn-outline-secondary'
                        onClick={viewProps.handleClose}>
                        <GrClose />
                    </button>
                </div>
                <div className='modal-body p-0 overflow-auto'>{viewProps.isLoading ? <Spinner /> : <RoleList />}</div>
                <ModalFooter
                    buttons={[{ text: 'Add role', hide: false, icon: FaPlus, type: ClassType.PRIMARY, onClick: () => viewProps.changeRolesModalOpen(true) }]}
                />
                <DefaultModal
                    handleClose={() => viewProps.changeRolesModalOpen(false)}
                    show={viewProps.rolesModalOpen}
                    customWidth={30}>
                    <CreateRoleForm
                        handleClose={() => viewProps.changeRolesModalOpen(false)}
                        dispatch={viewProps.dispatch}
                    />
                </DefaultModal>
            </DefaultModal>
        </div>
    );
};

export default RolesModal;
