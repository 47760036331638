import { accountStore } from '@frontend/account/redux';
import { authenticationMethodsStore } from '@frontend/authentication-methods/redux';
import { authorizationStore } from '@frontend/authorization';
import { badgeStore } from '@frontend/badge/redux';
import { certificateStore } from '@frontend/certificate/redux';
import { constraintStore } from '@frontend/constraint/redux';
import { contactStore } from '@frontend/contact/redux';
import { deviceStore, driverStore } from '@frontend/edge/redux';
import { eventStore } from '@frontend/events/redux';
import { integrationTestStore } from '@frontend/integration-test';
import { moduleStore } from '@frontend/module/redux';
import { notificationContentStore, notificationTemplateStore } from '@frontend/notification-service/redux';
import { packageStore } from '@frontend/package/redux';
import { productCatalogStore } from '@frontend/product-catalog/redux';
import { productCategoryStore } from '@frontend/product-category/redux';
import { productStore } from '@frontend/product/redux';
import { rakindaStore } from '@frontend/rakinda';
import { listStore, resolveStore } from '@frontend/repository';
import { settingStore } from '@frontend/setting/redux';
import { slotStore } from '@frontend/slot/redux';
import { spotStore } from '@frontend/spot/redux';
import { movementStore, wareStore } from '@frontend/stock/redux';
import { changeStore, seedStore } from '@frontend/sync/redux';
import { terminalStore } from '@frontend/terminal-service';
import { transactionStore } from '@frontend/transaction/redux';
import { userInterfaceButtonStore } from '@frontend/user-interface-button/redux';
import { navigationStore } from '@frontend/user-interface-templates';
import { userInterfaceStore } from '@frontend/user-interface/redux';
import { accessLogStore, userStore } from '@frontend/user/redux';
import { workflowStepStore } from '@frontend/workflow-step/redux';
import { workflowSharedStore, workflowStore, workflowVariablesStore } from '@frontend/workflow/redux';
import { configureStore } from '@reduxjs/toolkit';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

export const store = configureStore({
    reducer: {
        ...accountStore,
        ...workflowStore,
        ...workflowStepStore,
        ...workflowVariablesStore,
        ...workflowSharedStore,
        ...contactStore,
        ...userStore,
        ...terminalStore,
        ...authorizationStore,
        ...spotStore,
        ...moduleStore,
        ...slotStore,
        ...constraintStore,
        ...rakindaStore,
        ...transactionStore,
        ...eventStore,
        ...badgeStore,
        ...notificationTemplateStore,
        ...notificationContentStore,
        ...userInterfaceStore,
        ...userInterfaceButtonStore,
        ...integrationTestStore,
        ...driverStore,
        ...authenticationMethodsStore,
        ...deviceStore,
        ...productStore,
        ...wareStore,
        ...changeStore,
        ...seedStore,
        ...packageStore,
        ...movementStore,
        ...productCategoryStore,
        ...productCatalogStore,
        ...accessLogStore,
        ...settingStore,
        ...certificateStore,
        ...listStore,
        ...resolveStore,

        //TerminalUserInterface states
        ...navigationStore
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
