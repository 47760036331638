/* eslint-disable no-unused-vars */

/* eslint-disable @typescript-eslint/no-use-before-define */
import { AuthorizationWrapper } from '@frontend/authorization';
import { EntityType, Rights, useLocalStorageState } from '@frontend/common';
import { ENTITY_ICONS } from '@frontend/icons';
import { lazy, useRef } from 'react';

import noTextLogo from '../../../assets/images/logo200x200.png';
import logo from '../../../assets/images/telloport_logo_horizontal.png';
import NavbarItem from '../navbar-item/navbar-item.component';

const NavbarGroupItem = lazy(() => import('@frontend/elements').then((module) => ({ default: module.NavbarGroupItem })));

const ID = 'sidenav-main';

const Navbar = () => {
    const navbarRef = useRef<HTMLDivElement>(null);
    const [navbarPinned] = useLocalStorageState('navbar-pinned', true);

    return (
        <div
            id={ID}
            className='sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4'>
            <div className='sidenav-header d-flex flex-row justify-content-between align-items-center'>
                <a
                    className='navbar-brand m-0'
                    href='/'>
                    <img
                        src={navbarPinned ? logo : noTextLogo}
                        className='navbar-brand-img h-100'
                        alt='main_logo'
                    />
                </a>
            </div>

            <hr className='horizontal dark mt-0' />

            <div
                className='collapse navbar-collapse w-auto h-auto'
                id='sidenav-collapse-main'
                ref={navbarRef}>
                <ul className='navbar-nav h-100'>
                    <AuthorizationWrapper
                        showLoading={false}
                        requiredPermissions={[{ item: EntityType.ACCOUNT, right: Rights.READ, source: EntityType.ACCOUNT, uid: '?' }]}>
                        <NavbarItem
                            text='Accounts'
                            link={NavbarItems.ACCOUNT}
                            icon={<ENTITY_ICONS.ACCOUNT />}
                        />
                    </AuthorizationWrapper>
                    <AuthorizationWrapper
                        showLoading={false}
                        requiredPermissions={[
                            {
                                item: EntityType.TRANSACTION,
                                right: Rights.READ,
                                source: EntityType.ACCOUNT,
                                uid: '?'
                            }
                        ]}>
                        <NavbarItem
                            text='Transactions'
                            link={NavbarItems.TRANSACTION}
                            icon={<ENTITY_ICONS.TRANSACTION />}
                        />
                    </AuthorizationWrapper>
                    <AuthorizationWrapper
                        showLoading={false}
                        requiredPermissions={[
                            {
                                item: EntityType.PRODUCT || EntityType.WARE || EntityType.MOVEMENT || EntityType.CATALOG,
                                right: Rights.READ,
                                source: EntityType.ACCOUNT || EntityType.SPOT,
                                uid: '?'
                            }
                        ]}>
                        <NavbarGroupItem
                            title='Inventory'
                            navbarRef={navbarRef}
                            link='Inventory'>
                            <NavbarItem
                                text='Products'
                                link={NavbarItems.PRODUCT}
                                icon={<ENTITY_ICONS.PRODUCT />}
                                requiredPermissions={[{ item: EntityType.PRODUCT, right: Rights.READ, source: EntityType.ACCOUNT, uid: '?' }]}
                            />
                            <NavbarItem
                                text='Product categories'
                                link={NavbarItems.PRODUCT_CATEGORY}
                                icon={<ENTITY_ICONS.PRODUCT_CATEGORY />}
                                requiredPermissions={[{ item: EntityType.PRODUCT, right: Rights.READ, source: EntityType.ACCOUNT, uid: '?' }]}
                            />
                            <NavbarItem
                                text='Product catalogs'
                                link={NavbarItems.PRODUCT_CATALOG}
                                icon={<ENTITY_ICONS.PRODUCT_CATALOG />}
                                requiredPermissions={[{ item: EntityType.CATALOG, right: Rights.READ, source: EntityType.ACCOUNT, uid: '?' }]}
                            />
                            <NavbarItem
                                text='Stock updates'
                                link={NavbarItems.STOCK_UPDATES}
                                icon={<ENTITY_ICONS.MOVEMENT />}
                                requiredPermissions={[{ item: EntityType.MOVEMENT, right: Rights.READ, source: EntityType.SPOT, uid: '?' }]}
                            />
                        </NavbarGroupItem>
                    </AuthorizationWrapper>
                    <AuthorizationWrapper
                        showLoading={false}
                        requiredPermissions={[
                            {
                                source: EntityType.SPOT || EntityType.MODULE || EntityType.SLOT,
                                item: EntityType.SPOT || EntityType.MODULE || EntityType.SLOT || EntityType.ACCOUNT,
                                right: 'read',
                                uid: '?'
                            }
                        ]}>
                        <NavbarGroupItem
                            title='Spots'
                            link='spot-overview'
                            navbarRef={navbarRef}>
                            <NavbarItem
                                text='SPOTs'
                                link={NavbarItems.SPOT}
                                icon={<ENTITY_ICONS.SPOT />}
                                requiredPermissions={[{ item: EntityType.SPOT, right: Rights.READ, source: EntityType.SPOT, uid: '?' }]}
                            />
                            <NavbarItem
                                text='Modules'
                                link={NavbarItems.MODULE}
                                icon={<ENTITY_ICONS.MODULE />}
                                requiredPermissions={[{ item: EntityType.MODULE, right: Rights.READ, source: EntityType.SPOT, uid: '?' }]}
                            />
                            <NavbarItem
                                text='SLOTs'
                                link={NavbarItems.SLOT}
                                icon={<ENTITY_ICONS.SLOT />}
                                requiredPermissions={[{ item: EntityType.SLOT, right: Rights.READ, source: EntityType.SPOT, uid: '?' }]}
                            />
                        </NavbarGroupItem>
                    </AuthorizationWrapper>
                    <AuthorizationWrapper
                        showLoading={false}
                        requiredPermissions={[
                            {
                                item: EntityType.USER || EntityType.CONTACT || EntityType.BADGE,
                                right: Rights.READ,
                                source: EntityType.ACCOUNT || EntityType.CONTACT || EntityType.BADGE,
                                uid: '?'
                            }
                        ]}>
                        <NavbarGroupItem
                            title='User management'
                            link='user-management'
                            navbarRef={navbarRef}>
                            <NavbarItem
                                text='Users'
                                link={NavbarItems.USER}
                                icon={<ENTITY_ICONS.USER />}
                                requiredPermissions={[{ item: EntityType.USER, right: Rights.READ, source: EntityType.ACCOUNT, uid: '?' }]}
                            />
                            <NavbarItem
                                text='Contacts'
                                link={NavbarItems.CONTACTS}
                                icon={<ENTITY_ICONS.CONTACT />}
                                requiredPermissions={[{ item: EntityType.CONTACT, right: Rights.READ, source: EntityType.CONTACT, uid: '?' }]}
                            />
                            <NavbarItem
                                text='Badges'
                                link={NavbarItems.BADGE}
                                icon={<ENTITY_ICONS.BADGE />}
                                requiredPermissions={[{ item: EntityType.BADGE, right: Rights.READ, source: EntityType.BADGE, uid: '?' }]}
                            />
                            <NavbarItem
                                text='Access logs'
                                link={NavbarItems.ACCESS_LOG}
                                icon={<ENTITY_ICONS.ACCESS_LOG />}
                                requiredPermissions={[{ item: EntityType.ACCESS_LOG, right: Rights.READ, source: EntityType.ACCESS_LOG, uid: '?' }]}
                            />
                        </NavbarGroupItem>
                    </AuthorizationWrapper>
                </ul>
            </div>
        </div>
    );
};

export enum NavbarItems {
    ACCOUNT = 'accounts',
    ADMIN_ACCOUNT = 'admin-accounts',
    TRANSACTION = 'transactions',
    ADMIN_TRANSACTION = 'admin-transactions',
    CONTACTS = 'contacts',
    WORKFLOW = 'workflows',
    USER = 'users',
    ADMIN_USER = 'admin-users',
    TERMINAL = 'terminals',
    ADMIN_TERMINAL = 'admin-terminals',
    SPOT = 'spots',
    ADMIN_SPOT = 'admin-spots',
    RAKINDA = 'rakindas',
    ADMIN_RAKINDA = 'admin-rakindas',
    SLOT = 'slots',
    ADMIN_SLOT = 'admin-slots',
    BADGE = 'badges',
    ADMIN_BADGE = 'admin-badges',
    TEMPLATE = 'notification-templates',
    ADMIN_TEMPLATE = 'admin-notification-templates',
    USER_INTERFACE = 'user-interfaces',
    ADMIN_USER_INTERFACE = 'admin-user-interfaces',
    DRIVER = 'drivers',
    ADMIN_DRIVER = 'admin-drivers',
    DEVICE = 'devices',
    ADMIN_DEVICE = 'admin-devices',
    INTEGRATION_TEST = 'integration-tests',
    ADMIN_INTEGRATION_TEST = 'admin-integration-tests',
    MODULE = 'modules',
    ADMIN_MODULE = 'admin-modules',
    PRODUCT = 'products',
    ADMIN_PRODUCT = 'admin-products',
    CHANGE = 'changes',
    ADMIN_CHANGE = 'admin-changes',
    SEED = 'seeds',
    ADMIN_SEED = 'admin-seeds',
    PACKAGE = 'packages',
    ADMIN_PACKAGE = 'admin-packages',
    WARE = 'wares',
    ADMIN_WARE = 'admin-wares',
    EVENT = 'events',
    ADMIN_EVENT = 'admin-events',
    MOVEMENT = 'movements',
    ADMIN_MOVEMENT = 'admin-movements',
    PRODUCT_CATEGORY = 'product-categories',
    ADMIN_PRODUCT_CATEGORY = 'admin-product-categories',
    ADMIN_PRODUCT_CATALOG = 'admin-product-catalogs',
    PRODUCT_CATALOG = 'product-catalogs',
    ACCESS_LOG = 'access-logs',
    ADMIN_ACCESS_LOG = 'admin-access-logs',
    ADMIN_SETTINGS = 'admin-settings',
    CERTIFICATES = 'certificates',
    STOCK_UPDATES = 'stock-updates'
}

export default Navbar;
