import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { AuthenticationManager } from '../../../authentication-manager';

interface ViewProps {
    formValues: FormResult;
    changeFormValues: Dispatch<SetStateAction<FormResult>>;
    submitted: boolean;
    emailValid: boolean;
    passwordValid: boolean;
    passwordConfirmationValid: boolean;
    changeEmailValid: Dispatch<SetStateAction<boolean>>;
    changePasswordValid: Dispatch<SetStateAction<boolean>>;
    changePasswordConfirmationValid: Dispatch<SetStateAction<boolean>>;
    onSubmit: (e: any) => void;
    response: { type: string; message: string } | undefined;
}

interface FormResult {
    url: string;
    email: string;
    password: string;
    password_confirmation: string;
}

const useForgotPasswordConfirmation = (): ViewProps => {
    const { pathname } = useLocation();
    const token = pathname.split('/')[2];
    const [response, changeResponse] = useState<{ type: string; message: string } | undefined>(undefined);
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [formValues, changeFormValues] = useState<FormResult>({ url: '', email: '', password: '', password_confirmation: '' });
    const [emailValid, changeEmailValid] = useState<boolean>(true);
    const [passwordValid, changePasswordValid] = useState<boolean>(true);
    const [passwordConfirmationValid, changePasswordConfirmationValid] = useState<boolean>(true);

    const onSubmit = async (e: any) => {
        e.preventDefault();
        changeSubmitted(true);
        const response = await AuthenticationManager.getInstance().confirmForgotPassword(token, formValues);
        changeResponse(response);
    };

    useEffect(() => {
        if (response && response.type === 'success') {
            setTimeout(() => {
                window.location.href = '/';
            }, 3000);
        }
    }, [response]);

    return {
        changeFormValues,
        formValues,
        submitted,
        changeEmailValid,
        changePasswordConfirmationValid,
        changePasswordValid,
        emailValid,
        passwordConfirmationValid,
        passwordValid,
        onSubmit,
        response
    };
};

export default useForgotPasswordConfirmation;
