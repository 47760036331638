import { EmailInput, PasswordInput } from '@frontend/basic-forms';
import React from 'react';

import logo from '../../../../assets/images/telloport_logo_horizontal.png';
import { ConfirmationComponent } from '../../basic-sign-up/basic-sign-up-confirmation/basic-sign-up-confirmation.component';
import useForgotPasswordConfirmation from './forgot-password-confirmation.controller';

const ID = 'forgot-password-confirmation';

const ForgotPasswordConfirmation = () => {
    const viewProps = useForgotPasswordConfirmation();

    return (
        <div
            id={ID}
            className='d-flex flex-column justify-content-center align-items-center m-5'>
            <form
                className='card w-50'
                onSubmit={viewProps.onSubmit}>
                <div className='card-header text-center'>
                    <img
                        src={logo}
                        className='img-fluid w-25'
                        alt=''
                    />
                    <h4 className='m-3'>Forgot password confirmation</h4>
                </div>
                {viewProps.response ? (
                    <div className='card-body'>
                        <div className='d-flex flex-column align-items-center'>
                            <ConfirmationComponent
                                submitted={viewProps.submitted}
                                response={viewProps.response}
                            />
                        </div>
                    </div>
                ) : (
                    <>
                        <div className='card-body d-flex flex-column align-items-center w-100'>
                            <EmailInput
                                className='w-100'
                                required
                                label='Email'
                                errorMessage='Please fill in a valid email.'
                                autoFocus
                                value={viewProps.formValues.email}
                                onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, email: value })}
                                isValidCallback={(value) => {
                                    if (viewProps.submitted) viewProps.changeEmailValid(value);
                                }}
                                submitted={viewProps.submitted}
                                valid={viewProps.emailValid}
                                noConditionedStyling
                            />
                            <PasswordInput
                                className='w-100'
                                required
                                label='Password'
                                errorMessage='Please fill in your password.'
                                value={viewProps.formValues.password}
                                onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, password: value })}
                                isValidCallback={(value) => {
                                    if (viewProps.submitted) viewProps.changePasswordValid(value);
                                }}
                                noConditionedStyling
                                valid={viewProps.passwordValid}
                            />
                            <PasswordInput
                                className='w-100'
                                required
                                label='Confirm password'
                                value={viewProps.formValues.password_confirmation}
                                onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, password_confirmation: value })}
                                isValidCallback={(value) => {
                                    viewProps.changePasswordConfirmationValid(value);
                                }}
                                toRepeat={viewProps.formValues.password}
                            />
                        </div>
                        <div className='card-footer text-center'>
                            <button
                                type='submit'
                                className='btn btn-primary'
                                disabled={!viewProps.emailValid || !viewProps.passwordValid || viewProps.submitted}>
                                Change Password
                            </button>
                        </div>
                    </>
                )}
            </form>
        </div>
    );
};

export default ForgotPasswordConfirmation;
