import React from 'react';
import { useLocation } from 'react-router-dom';

import useAuthentication from './authentication.controller';
import BasicLogin from './basic-login/basic-login.component';
import BasicSignUpConfirmation from './basic-sign-up/basic-sign-up-confirmation/basic-sign-up-confirmation.component';
import BasicSignUp from './basic-sign-up/basic-sign-up.component';
import ForgotPasswordConfirmation from './forgot-password/forgot-password-confirmation/forgot-password-confirmation.component';
import ForgotPassword from './forgot-password/forgot-password.component';

export const ID = 'authentication';
export interface Props {
    children: React.ReactNode;
}
export const Authentication = (props: Props) => {
    const viewProps = useAuthentication();
    const { pathname } = useLocation();
    const validPath = pathname.split('/')[1];
    const AuthenticationContent = () => {
        if (validPath === 'sign-up') {
            return <BasicSignUp />;
        } else if (validPath === 'sign-up-confirmation') {
            return <BasicSignUpConfirmation />;
        } else if (validPath === 'forgot-password') {
            return <ForgotPassword />;
        } else if (validPath === 'forgot-password-confirmation') {
            return <ForgotPasswordConfirmation />;
        } else return <BasicLogin callback={viewProps.onLoginSucceeded} />;
    };
    return <div id={ID}>{viewProps.authenticated ? props.children : <AuthenticationContent />}</div>;
};
