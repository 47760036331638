import { Authentication } from '@frontend/authentication-v2';
import { AuthorizationProvider } from '@frontend/authorization';
import { useBadges } from '@frontend/badge/events';
import { seedBadges } from '@frontend/badge/redux';
import { useLocalStorageState, useScripts } from '@frontend/common';
import { useDevices } from '@frontend/device/events';
import { useDrivers } from '@frontend/edge/events';
import { Header } from '@frontend/header';
import { TranslationProvider } from '@frontend/lang';
import { usePackages } from '@frontend/package/events';
import { seedRakindas, useRakindas } from '@frontend/rakinda';
import { useSlots } from '@frontend/slot/events';
import { seedTerminals, useTerminals } from '@frontend/terminal-service';
import { useTransactions } from '@frontend/transaction/events';
import { lazy } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import RestrictionModalContainer from './authorization/restrictions/restriction-modal-container/restriction-modal-container.component';
import RolesModalContainer from './authorization/roles-modal-container/roles-modal-container.component';
import Footer from './footer/footer.component';
import Navbar from './navigation/navbar/navbar.component';
import { useAppDispatch } from './redux/store';
import Resolver from './utils/resolver';

const CertificateOverviewComponent = lazy(() => import('./certificates/certificate-overview/certificate-overview.component'));

const AsyncComponent = lazy(() => import('@frontend/elements').then((module) => ({ default: module.AsyncComponent })));
const BasicAuthDetailModalWrapper = lazy(() => import('@frontend/elements').then((module) => ({ default: module.BasicAuthDetailModalWrapper })));
const DetailModalContainer = lazy(() => import('@frontend/elements').then((module) => ({ default: module.DetailModalContainer })));
const EventDetailModalWrapper = lazy(() => import('@frontend/events/components').then((module) => ({ default: module.EventDetailModalWrapper })));
const TerminalDetailModalWrapper = lazy(() => import('@frontend/elements').then((module) => ({ default: module.TerminalDetailModalWrapper })));

const AccountDetailModalWrapper = lazy(() => import('@frontend/account/components').then((module) => ({ default: module.AccountDetailModalWrapper })));
const AccountDetailModule = lazy(() => import('@frontend/account/components').then((module) => ({ default: module.AccountDetailPage })));
const AccountModule = lazy(() => import('@frontend/account/components').then((module) => ({ default: module.AccountList })));

const BadgeDetailModalWrapper = lazy(() => import('@frontend/badge/components').then((module) => ({ default: module.BadgeDetailModalWrapper })));
const BadgeDetailModule = lazy(() => import('./account-badges/badge-detail/badge-detail-page/badge-detail-page.component'));
const BadgeModule = lazy(() => import('./account-badges/badge-list/badge-list.component'));

const ChangeListModule = lazy(() => import('@frontend/sync/components').then((module) => ({ default: module.ChangeList })));

const ContactDetailModalWrapper = lazy(() => import('@frontend/contact/components').then((module) => ({ default: module.ContactDetailModalWrapper })));
const ContactDetailModule = lazy(() => import('@frontend/contact/components').then((module) => ({ default: module.ContactDetailPage })));
const ContactModule = lazy(() => import('@frontend/contact/components').then((module) => ({ default: module.ContactList })));

const DeviceDetailModalWrapper = lazy(() => import('@frontend/device/components').then((module) => ({ default: module.DeviceDetailModalWrapper })));
const DeviceDetailModule = lazy(() => import('@frontend/device/components').then((module) => ({ default: module.DeviceDetailPage })));
const DeviceListModule = lazy(() => import('@frontend/device/components').then((module) => ({ default: module.DeviceList })));

const DriverDetailModalWrapper = lazy(() => import('@frontend/edge/components').then((module) => ({ default: module.DriverDetailModalWrapper })));
const DriverDetailModule = lazy(() => import('@frontend/edge/components').then((module) => ({ default: module.DriverDetailPage })));
const DriverListModule = lazy(() => import('@frontend/edge/components').then((module) => ({ default: module.DriverList })));

const EventEntitiesModule = lazy(() => import('./events/event-entities-list/event-entities-list.component'));

const EventsModule = lazy(() => import('@frontend/events/components').then((module) => ({ default: module.EventsList })));

const IntegrationTestModule = lazy(() => import('./integration-tests/integration-test-list/integration-test-list.component'));

const ModuleDetailModalWrapper = lazy(() => import('@frontend/module/components').then((module) => ({ default: module.ModuleDetailModalWrapper })));
const ModuleDetailModule = lazy(() => import('@frontend/module/components').then((module) => ({ default: module.ModuleDetailPage })));
const ModuleListModule = lazy(() => import('@frontend/module/components').then((module) => ({ default: module.ModuleList })));
const ModuleVisualizerModule = lazy(() => import('@frontend/module/components').then((module) => ({ default: module.ModuleVisualizer })));

const MovementDetailModalWrapper = lazy(() => import('@frontend/stock/components').then((module) => ({ default: module.MovementDetailModalWrapper })));
const MovementDetailModule = lazy(() => import('@frontend/stock/components').then((module) => ({ default: module.MovementDetailPage })));
const MovementReportModule = lazy(() => import('@frontend/stock/components').then((module) => ({ default: module.MovementReportList })));

const NotificationContentDetailModule = lazy(() =>
    import('@frontend/notification-service/components').then((module) => ({ default: module.NotificationContentDetail }))
);
const NotificationTemplateDetailModule = lazy(() =>
    import('@frontend/notification-service/components').then((module) => ({ default: module.NotificationTemplateDetail }))
);
const NotificationTemplateModule = lazy(() =>
    import('@frontend/notification-service/components').then((module) => ({ default: module.NotificationTemplateList }))
);

const PackageDetailModalWrapper = lazy(() => import('@frontend/package/components').then((module) => ({ default: module.PackageDetailModalWrapper })));
const PackageDetailModule = lazy(() => import('@frontend/package/components').then((module) => ({ default: module.PackageDetailpage })));
const PackageModule = lazy(() => import('@frontend/package/components').then((module) => ({ default: module.PackageList })));

const TransactionOverview = lazy(() => import('@frontend/transaction/components').then((module) => ({ default: module.TransactionOverview })));

const ProductDetailModal = lazy(() => import('@frontend/product/components').then((module) => ({ default: module.ProductDetailModal })));
const ProductDetailModule = lazy(() => import('@frontend/product/components').then((module) => ({ default: module.ProductDetailPage })));
const ProductListModule = lazy(() => import('@frontend/product/components').then((module) => ({ default: module.ProductList })));
const ProductReportListModule = lazy(() => import('@frontend/product/components').then((module) => ({ default: module.ProductReportList })));

const ProductCatalogProductsList = lazy(() =>
    import('@frontend/product-catalog/components').then((module) => ({ default: module.ProductCatalogProductsList }))
);
const ProductCatalogOverviewModule = lazy(() => import('@frontend/product-catalog/components').then((module) => ({ default: module.ProductCatalogOverview })));
const ProductCatalogDetailModalWrapper = lazy(() =>
    import('@frontend/product-catalog/components').then((module) => ({ default: module.ProductCatalogDetailModalWrapper }))
);

const ProductCategoryDetailModalWrapper = lazy(() =>
    import('@frontend/product-category/components').then((module) => ({ default: module.ProductCategoryDetailModalWrapper }))
);
const ProductCategoryDetailModule = lazy(() =>
    import('@frontend/product-category/components').then((module) => ({ default: module.ProductCategoryDetailPage }))
);
const ProductCategoryListModule = lazy(() => import('@frontend/product-category/components').then((module) => ({ default: module.ProductCategoryList })));

const ProfileModule = lazy(() => import('@frontend/elements').then((module) => ({ default: module.Profile })));

const RakindaDetailModalWrapper = lazy(() => import('@frontend/rakinda').then((module) => ({ default: module.RakindaDetailModalWrapper })));
const RakindaDetailModule = lazy(() => import('./rakindas/rakinda-detail/rakinda-detail-page/rakinda-detail-page.component'));
const RakindaModule = lazy(() => import('./rakindas/rakinda-list/rakinda-list.component'));

const SeedListModule = lazy(() => import('@frontend/sync/components').then((module) => ({ default: module.SeedList })));

const SlotDetailModalWrapper = lazy(() => import('@frontend/slot/components').then((module) => ({ default: module.SlotDetailModalWrapper })));
const SlotDetailModule = lazy(() => import('@frontend/slot/components').then((module) => ({ default: module.SlotDetailPage })));
const SlotModule = lazy(() => import('@frontend/slot/components').then((module) => ({ default: module.SlotList })));

const SpotDetailModalWrapper = lazy(() => import('@frontend/spot/components').then((module) => ({ default: module.SpotDetailModalWrapper })));
const SpotDetailModule = lazy(() => import('@frontend/spot/components').then((module) => ({ default: module.SpotDetailPage })));
const SpotModule = lazy(() => import('@frontend/spot/components').then((module) => ({ default: module.SpotList })));

const WareDetailModalWrapper = lazy(() => import('@frontend/stock/components').then((module) => ({ default: module.WareDetailModalWrapper })));

const TransactionDetailModalWrapper = lazy(() =>
    import('@frontend/transaction/components').then((module) => ({ default: module.TransactionDetailModalWrapper }))
);
const WarehouseTransactionDetailModalWrapper = lazy(() =>
    import('@frontend/transaction/components').then((module) => ({ default: module.WarehouseTransactionDetailModalWrapper }))
);
const TransactionDetailModule = lazy(() => import('@frontend/transaction/components').then((module) => ({ default: module.TransactionDetailPage })));
const TransactionModule = lazy(() => import('@frontend/transaction/components').then((module) => ({ default: module.TransactionList })));

const UserDetailModal = lazy(() => import('@frontend/user/common').then((module) => ({ default: module.UserDetailModal })));
const UserDetailModule = lazy(() => import('@frontend/user/common').then((module) => ({ default: module.UserDetailPage })));
const UserModule = lazy(() => import('@frontend/user/common').then((module) => ({ default: module.UserList })));

const UserInterfaceDetailModalWrapper = lazy(() =>
    import('@frontend/user-interface/components').then((module) => ({ default: module.UserInterfaceDetailModalWrapper }))
);
const UserInterfaceDetailModule = lazy(() => import('@frontend/user-interface/components').then((module) => ({ default: module.UserInterfaceDetail })));
const UserInterfaceModule = lazy(() => import('@frontend/user-interface/components').then((module) => ({ default: module.UserInterfaceList })));

const WorkflowDetailModule = lazy(() => import('@frontend/workflow/components').then((module) => ({ default: module.WorkflowDetailPage })));
const WorkflowModule = lazy(() => import('@frontend/workflow/components').then((module) => ({ default: module.WorkflowList })));

const WorkflowStepFieldsDetailModalWrapper = lazy(() =>
    import('@frontend/workflow-step/components').then((module) => ({ default: module.WorkflowStepFieldsModalWrapper }))
);

const ChangePasswordForm = lazy(() => import('./authentication/change-password/change-password.component'));
export function App() {
    useScripts('https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js');
    useScripts('https://kit.fontawesome.com/42d5adcbca.js');
    const dispatch = useAppDispatch();
    const [navbarPinned] = useLocalStorageState('navbar-pinned', true);

    useTransactions({ dispatch, enablePubSub: true });
    useSlots({ dispatch, enablePubSub: true });
    useDevices({ dispatch, enablePubSub: true });
    useDrivers({ dispatch, enablePubSub: true });

    useRakindas({
        seedStore: (rkd) => {
            dispatch(seedRakindas(rkd));
        }
    });

    useTerminals({
        seedStore: (trm) => {
            dispatch(seedTerminals(trm));
        }
    });

    useBadges({
        seedStore: (bdg) => {
            dispatch(seedBadges(bdg));
        }
    });
    usePackages({ dispatch });

    return (
        <TranslationProvider>
            <div className={`g-sidenav-show bg-gray-100 g-sidenav-${navbarPinned ? 'pinned' : 'hidden'}`}>
                <div className='min-height-300 bg-primary position-absolute w-100 sticky-top top-bar' />
                <Router>
                    <Authentication>
                        <AuthorizationProvider dispatch={dispatch}>
                            <Resolver>
                                <Navbar />
                                <main className='main-content position-relative border-radius-lg'>
                                    <Header dispatch={dispatch} />
                                    <div className='container-fluid ps-4'>
                                        <AsyncComponent>
                                            <Routes>
                                                <Route path='accounts'>
                                                    <Route
                                                        index
                                                        element={<AccountModule dispatch={dispatch} />}
                                                    />
                                                    <Route path=':accountId'>
                                                        <Route
                                                            index
                                                            path='detail'
                                                            element={<AccountDetailModule dispatch={dispatch} />}
                                                        />

                                                        <Route path='workflows'>
                                                            <Route
                                                                path=':workflowId'
                                                                element={<WorkflowDetailModule dispatch={dispatch} />}
                                                            />
                                                            <Route
                                                                index
                                                                element={<WorkflowModule dispatch={dispatch} />}
                                                            />
                                                        </Route>
                                                        <Route path='products'>
                                                            <Route
                                                                path=':productId/detail'
                                                                element={<ProductDetailModule dispatch={dispatch} />}
                                                            />
                                                            <Route
                                                                index
                                                                element={<ProductReportListModule dispatch={dispatch} />}
                                                            />
                                                        </Route>
                                                        <Route path='user-interfaces'>
                                                            <Route
                                                                path=':userInterfaceId/detail'
                                                                element={<UserInterfaceDetailModule dispatch={dispatch} />}
                                                            />
                                                            <Route
                                                                index
                                                                element={<UserInterfaceModule dispatch={dispatch} />}
                                                            />
                                                        </Route>
                                                        <Route path='notification-templates'>
                                                            <Route
                                                                index
                                                                element={<NotificationTemplateModule dispatch={dispatch} />}
                                                            />
                                                            <Route path=':templateId'>
                                                                <Route
                                                                    index
                                                                    element={<NotificationTemplateDetailModule dispatch={dispatch} />}
                                                                />
                                                                <Route path='contents'>
                                                                    <Route
                                                                        path=':contentId/detail'
                                                                        element={<NotificationContentDetailModule dispatch={dispatch} />}
                                                                    />
                                                                </Route>
                                                            </Route>
                                                        </Route>
                                                        <Route path='transactions'>
                                                            <Route
                                                                index
                                                                element={<TransactionModule dispatch={dispatch} />}
                                                            />
                                                            <Route path=':transactionId'>
                                                                <Route
                                                                    path='detail'
                                                                    element={<TransactionDetailModule dispatch={dispatch} />}
                                                                />
                                                                <Route path='packages'>
                                                                    <Route
                                                                        path=':packageId/detail'
                                                                        element={<PackageDetailModule dispatch={dispatch} />}
                                                                    />
                                                                    <Route
                                                                        index
                                                                        element={<PackageModule dispatch={dispatch} />}
                                                                    />
                                                                </Route>
                                                            </Route>
                                                        </Route>
                                                        <Route path='product-catalogs'>
                                                            <Route path=':productCatalogId'>
                                                                <Route
                                                                    path='products'
                                                                    element={<ProductCatalogProductsList dispatch={dispatch} />}
                                                                />
                                                            </Route>
                                                        </Route>
                                                    </Route>
                                                </Route>
                                                <Route path='badges'>
                                                    <Route
                                                        index
                                                        element={<BadgeModule />}
                                                    />
                                                    <Route
                                                        path=':badgeId/detail'
                                                        element={<BadgeDetailModule />}
                                                    />
                                                </Route>
                                                <Route path='notification-templates'>
                                                    <Route
                                                        index
                                                        element={<NotificationTemplateModule dispatch={dispatch} />}
                                                    />
                                                </Route>
                                                <Route path='/workflows'>
                                                    <Route
                                                        index
                                                        element={<WorkflowModule dispatch={dispatch} />}
                                                    />
                                                </Route>
                                                <Route path='/transactions'>
                                                    <Route
                                                        index
                                                        element={<TransactionOverview dispatch={dispatch} />}
                                                    />
                                                    <Route
                                                        path=':transactionId/detail'
                                                        element={<TransactionDetailModule dispatch={dispatch} />}
                                                    />
                                                </Route>

                                                <Route path='/contacts'>
                                                    <Route
                                                        index
                                                        element={<ContactModule dispatch={dispatch} />}
                                                    />
                                                    <Route
                                                        path=':contactId/detail'
                                                        element={<ContactDetailModule dispatch={dispatch} />}
                                                    />
                                                </Route>
                                                <Route path='/users'>
                                                    <Route
                                                        index
                                                        element={<UserModule dispatch={dispatch} />}
                                                    />
                                                    <Route
                                                        path=':userId/detail'
                                                        element={<UserDetailModule dispatch={dispatch} />}
                                                    />
                                                </Route>
                                                <Route path='spots'>
                                                    <Route
                                                        index
                                                        element={<SpotModule dispatch={dispatch} />}
                                                    />
                                                    <Route
                                                        path=':spotId/detail'
                                                        element={<SpotDetailModule dispatch={dispatch} />}
                                                    />
                                                    <Route
                                                        path=':spotId/overview'
                                                        element={<ModuleVisualizerModule dispatch={dispatch} />}
                                                    />
                                                </Route>
                                                <Route path='slots'>
                                                    <Route
                                                        index
                                                        element={<SlotModule dispatch={dispatch} />}
                                                    />
                                                    <Route
                                                        path=':slotId/detail'
                                                        element={<SlotDetailModule dispatch={dispatch} />}
                                                    />
                                                </Route>
                                                <Route path='modules'>
                                                    <Route
                                                        index
                                                        element={<ModuleListModule dispatch={dispatch} />}
                                                    />
                                                    <Route
                                                        path=':moduleId/detail'
                                                        element={<ModuleDetailModule dispatch={dispatch} />}
                                                    />
                                                    <Route
                                                        path=':moduleId/overview'
                                                        element={<ModuleVisualizerModule dispatch={dispatch} />}
                                                    />
                                                </Route>
                                                <Route path='products'>
                                                    <Route
                                                        index
                                                        element={<ProductReportListModule dispatch={dispatch} />}
                                                    />
                                                </Route>
                                                <Route path='product-categories'>
                                                    <Route
                                                        index
                                                        element={<ProductCategoryListModule dispatch={dispatch} />}
                                                    />
                                                    <Route
                                                        path=':productCategoryId/detail'
                                                        element={<ProductCategoryDetailModule dispatch={dispatch} />}
                                                    />
                                                </Route>
                                                <Route path='/rakindas'>
                                                    <Route
                                                        index
                                                        element={<RakindaModule />}
                                                    />
                                                    <Route
                                                        path=':rakindaId/detail'
                                                        element={<RakindaDetailModule />}
                                                    />
                                                </Route>
                                                <Route path='/user-interfaces'>
                                                    <Route
                                                        index
                                                        element={<UserInterfaceModule dispatch={dispatch} />}
                                                    />
                                                </Route>
                                                <Route path='devices'>
                                                    <Route
                                                        index
                                                        element={<DeviceListModule dispatch={dispatch} />}
                                                    />
                                                    <Route
                                                        path=':deviceId/detail'
                                                        element={<DeviceDetailModule dispatch={dispatch} />}
                                                    />
                                                </Route>
                                                <Route path='drivers'>
                                                    <Route
                                                        index
                                                        element={<DriverListModule dispatch={dispatch} />}
                                                    />
                                                    <Route
                                                        path=':driverId/detail'
                                                        element={<DriverDetailModule dispatch={dispatch} />}
                                                    />
                                                </Route>
                                                <Route path='integration-tests'>
                                                    <Route
                                                        index
                                                        element={<IntegrationTestModule />}
                                                    />
                                                </Route>
                                                <Route path='/events'>
                                                    <Route
                                                        index
                                                        element={<EventsModule dispatch={dispatch} />}
                                                    />
                                                    <Route
                                                        path=':eventId/entities'
                                                        element={<EventEntitiesModule />}
                                                    />
                                                </Route>

                                                <Route
                                                    path='/'
                                                    element={<Navigate to='/profile' />}
                                                />
                                                <Route
                                                    path='/*'
                                                    element={<Navigate to='/profile' />}
                                                />
                                                <Route path='/packages'>
                                                    <Route
                                                        index
                                                        element={<PackageModule dispatch={dispatch} />}
                                                    />
                                                </Route>
                                                <Route path='/changes'>
                                                    <Route
                                                        index
                                                        element={<ChangeListModule dispatch={dispatch} />}
                                                    />
                                                </Route>
                                                <Route path='/seeds'>
                                                    <Route
                                                        index
                                                        element={<SeedListModule dispatch={dispatch} />}
                                                    />
                                                </Route>
                                                <Route path='stock-updates'>
                                                    <Route
                                                        index
                                                        element={<MovementReportModule dispatch={dispatch} />}
                                                    />
                                                    <Route
                                                        path=':movementId/detail'
                                                        element={<MovementDetailModule dispatch={dispatch} />}
                                                    />
                                                </Route>
                                                <Route path='product-catalogs'>
                                                    <Route
                                                        index
                                                        element={<ProductCatalogOverviewModule dispatch={dispatch} />}
                                                    />
                                                </Route>
                                                <Route path='change-password'>
                                                    <Route
                                                        index
                                                        element={<ChangePasswordForm />}
                                                    />
                                                </Route>
                                                <Route path='/profile'>
                                                    <Route
                                                        index
                                                        element={<ProfileModule dispatch={dispatch} />}
                                                    />
                                                </Route>
                                                <Route path='/certificates'>
                                                    <Route
                                                        index
                                                        element={<CertificateOverviewComponent />}
                                                    />
                                                </Route>
                                            </Routes>
                                            <DetailModalContainer
                                                modalWrapper={{
                                                    transaction: (props: any) => <TransactionDetailModalWrapper {...props} />,
                                                    warehouse_transaction: (props: any) => <WarehouseTransactionDetailModalWrapper {...props} />,
                                                    event: (props: any) => <EventDetailModalWrapper {...props} />,
                                                    account: (props: any) => <AccountDetailModalWrapper {...props} />,
                                                    contact: (props: any) => <ContactDetailModalWrapper {...props} />,
                                                    terminal: (props: any) => <TerminalDetailModalWrapper {...props} />,
                                                    rakinda: (props: any) => <RakindaDetailModalWrapper {...props} />,
                                                    slot: (props: any) => <SlotDetailModalWrapper {...props} />,
                                                    user: (props: any) => <UserDetailModal {...props} />,
                                                    spot: (props: any) => <SpotDetailModalWrapper {...props} />,
                                                    badge: (props: any) => <BadgeDetailModalWrapper {...props} />,
                                                    user_interface: (props: any) => <UserInterfaceDetailModalWrapper {...props} />,
                                                    basic_auth: (props: any) => <BasicAuthDetailModalWrapper {...props} />,
                                                    device: (props: any) => <DeviceDetailModalWrapper {...props} />,
                                                    driver: (props: any) => <DriverDetailModalWrapper {...props} />,
                                                    module: (props: any) => <ModuleDetailModalWrapper {...props} />,
                                                    product: (props: any) => <ProductDetailModal {...props} />,
                                                    package: (props: any) => <PackageDetailModalWrapper {...props} />,
                                                    workflow_step: (props: any) => <WorkflowStepFieldsDetailModalWrapper {...props} />,
                                                    ware: (props: any) => <WareDetailModalWrapper {...props} />,
                                                    movement: (props: any) => <MovementDetailModalWrapper {...props} />,
                                                    product_category: (props: any) => <ProductCategoryDetailModalWrapper {...props} />,
                                                    product_catalog: (props: any) => <ProductCatalogDetailModalWrapper {...props} />
                                                }}
                                                dispatch={dispatch}
                                            />
                                            <RolesModalContainer />
                                            <RestrictionModalContainer />
                                        </AsyncComponent>
                                    </div>
                                    <Footer />
                                </main>
                            </Resolver>
                        </AuthorizationProvider>
                    </Authentication>
                </Router>
                <ToastContainer />
            </div>
        </TranslationProvider>
    );
}

export default App;
